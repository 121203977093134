.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 25px;
  width: 35px;
  margin: 17.5px auto 17.5px auto;
  background-image: url("./assets/images/logosing.png");;
}

.site-layout .site-layout-background {
  background: #fff;
 box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.ant-table-content > table {
  table-layout: fixed !important;
}
.ant-table-content {
  overflow: scroll !important;
}

.ant-table-thead > tr > th {
  text-transform: uppercase!important;
}
.ant-input-number {
  border-radius: 6px;

}
.ant-input-number-input {
  text-align: left !important;
}