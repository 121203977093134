@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Montserrat";
}

@font-face {
  font-family: "Montserrat";
  src: url("./fonts/Montserrat.ttf") format("truetype");
}

body {
  margin: 0;
  background-color: #f0f2f5;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-item-filter {
  width: 240px;
  margin-bottom: 5px;
}

.form-datepicker-filter {
  width: 450px;
  margin-bottom: 5px;
}

.ant-form-vertical
  > .ant-form-item
  > .ant-form-item-row
  > .ant-form-item-label {
  padding-bottom: 0px;
}

.ant-form-vertical
  > .ant-form-item
  > .ant-form-item-row
  > .ant-form-item-label
  > label {
  font-size: 12px;
}

.table-setting {
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: hidden;
  border-width: 1px;
}

.ant-table-thead > tr > .ant-table-cell {
  background-color: #f3f2f7;
  font-size: 12px;
  font-weight: 600;
}

.ant-select-selector, .ant-input , .ant-picker {
  border-radius: 6px !important;
}

.ant-btn {
  border-radius: 6px !important;
  /* background-color: #5a4edd; */
}

.ant-btn-primary {
  background-color: #5a4edd;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.ant-input-number-input { text-align: center; }

.ant-pagination {
  padding-right: 20px;
}